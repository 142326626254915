<script>
	import { playSound, Sounds } from "../services/audio";
	import { ProfilePicture } from "da-components";
	
	export let votersArray = [];

	let overflow = false;
	let extraNumberOfUsers = 0;
	let displayArray = [];

	if (votersArray.length > 5) {
		overflow = true;
		displayArray = votersArray.slice(0, 5);
		extraNumberOfUsers = votersArray.length - 5;
	} else {
		displayArray = votersArray;
	}

	let showDropdown = false;
</script>

<div class="profiles" on:click={() => {
	playSound(Sounds.click);
	showDropdown = !showDropdown;
}}> 
    {#each displayArray as voter}
		<div class="profile"> 
			<ProfilePicture
				src={voter?.profilePicture}
				userName={voter?.userName}
				size={26}
			/>
		</div> 
    {/each}
	{#if overflow}
		+ {extraNumberOfUsers}
	{/if}
</div>

{#if showDropdown}
	<div class="dropdown">
		{#each votersArray as voter}
			<div class="userRow">
				<div class="profile"> 
					<ProfilePicture
						src={voter?.profilePicture}
						userName={voter?.userName}
						size={26}
					/>
				</div> 
				<div class="voterName">{voter.userName.length <=8 ? voter.userName : voter.userName.split("").splice(0, 8).join("") + "..."}</div>
			</div>
		{/each}
	</div>
	
{/if}

<style>
	.dropdown {
		position: absolute;
		background-color: #f5f5f5e6;
		font-size: 0.8rem;
		padding: 0.5rem;
		font-weight: 400;
		min-width: 12rem;
		max-width: 12rem;
		border-radius: 0.5rem;
		box-shadow: -2px 2px 3px #00000040;
		top: 30vh;
		overflow: auto;
		max-height: 7rem;
	}

	.voterName {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.userRow {
		display: inline-flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 1rem;
		flex-direction: row;
		justify-content: flex-start;
		align-content: center;
		padding: 0.1rem 0.5rem;
		width: 100%;
	}

	.profile{
		display: inline;
		filter: drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.25));
		-webkit-filter: drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.25));
		margin-left: -0.5rem;
	}
	
	.profiles {
		cursor: pointer;
		display: flex;
	}
	::-webkit-scrollbar {
		display: none;
	}
</style>