<script>
	import { GameHeader } from "da-components/v0";
	import {
		sound,
		exitModalStore,
	} from "../services/store";
	import { onDestroy } from "svelte";
	import { playSound, Sounds } from "../services/audio";

	export let score;
	export let time;
	export let isHost = true;

	let isSoundOn = true;
	let hostRoundController = false;
	let showModal = false;

	const exitModalStoreUnsubscribe = exitModalStore.subscribe(val => {
		showModal = val;
	});


	onDestroy(() => {
		exitModalStoreUnsubscribe();
	});

	$: soundState = $sound;
		
	$: if (soundState === false) {
		isSoundOn = false;
		playSound("MUTE");
	} else {
		isSoundOn = true;
		playSound("UNMUTE");
	}

	const soundButtonHandler = isSoundOn => {
		if (isSoundOn === true) {
			isSoundOn = true;
			sound.update(() => true);
			playSound("CLICK");
		} else if (isSoundOn === false) {
			playSound("CLICK");
			isSoundOn = false;
			sound.update(() => false);
		}
	};

	const getTime = time => {
		if (time !== undefined && time >= 0) {
			if (time < 10) {
				return `00:0${time}`;
			}
			return `00:${time}`;
		} else if (time <= 0) {
			return "00:00";
		} else return "00:10";
	};

	const endRoundonClick = () => {
		exitModalStore.set(true);
	};
</script>

<GameHeader
	noOfCoins={score}
	showRules={false}
	gameNameSvg="/svg/logo.svg"
	{isHost}
	{soundButtonHandler}
	{isSoundOn}
	time={getTime(time)}
	themeSupported={false}
	{endRoundonClick}
	onMouseDown={() => playSound(Sounds.click)}
/>

<style>
</style>