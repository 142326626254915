import { get } from "svelte/store";
import { sound } from "./store";
const bgMusic = new Audio("/music/bgMusic.mp3");
const clickSound = new Audio("/music/click.wav");
const formToVote = new Audio("/music/formTransition.wav");
const bigCountdown = new Audio("/music/countdown.wav");
const fastFourSec = new Audio("/music/fastFourSec.mp3");
const waitingTransition = new Audio("/music/waitingTransition.wav");
const notVote = new Audio("/music/notVote.wav");
const votingTransition = new Audio("/music/votingTransition.wav");
const correct = new Audio("/music/correct.mp3");
const wrong = new Audio("/music/wrong.mp3");
const clap = new Audio("/music/leaderboardClapping.mp3");
bgMusic.loop = true;
export var Sounds;
(function (Sounds) {
    Sounds["bg"] = "BGMUSIC";
    Sounds["stopbg"] = "BGMUSICSTOP";
    Sounds["click"] = "CLICK";
    Sounds["formtovote"] = "FORMTOVOTE";
    Sounds["bigCountdown"] = "BIGCOUNTDOWN";
    Sounds["bigCountdownStop"] = "BIGCOUNTDOWNSTOP";
    Sounds["fastfourSec"] = "FASTFOURSEC";
    Sounds["stopfastfourSec"] = "FASTFOURSECSTOP";
    Sounds["waiting"] = "WAITINGTRANSITION";
    Sounds["stopwaiting"] = "STOPWAITINGTRANSITION";
    Sounds["notVote"] = "NOTVOTE";
    Sounds["stopNotVote"] = "STOPNOTVOTE";
    Sounds["correct"] = "CORRECT";
    Sounds["wrong"] = "WRONG";
    Sounds["clap"] = "CLAP";
    Sounds["votingTransition"] = "VOTINGTRANSITION";
    Sounds["mute"] = "MUTE";
    Sounds["unmute"] = "UNMUTE";
})(Sounds || (Sounds = {}));
export const playSound = (type) => {
    let value = get(sound);
    switch (type) {
        case "BGMUSIC":
            play(bgMusic, 0.2, value, false);
            break;
        case "BGMUSICSTOP":
            stop(bgMusic);
            break;
        case "CLICK":
            play(clickSound, 0.3, value);
            break;
        case "FORMTOVOTE":
            play(formToVote, 0.2, value);
            break;
        case "BIGCOUNTDOWN":
            play(bigCountdown, 0.3, value, false);
            break;
        case "BIGCOUNTDOWNSTOP":
            stop(bigCountdown);
            break;
        case "FASTFOURSEC":
            play(fastFourSec, 0.3, value, false);
            break;
        case "FASTFOURSECSTOP":
            stop(fastFourSec);
            break;
        case "WAITINGTRANSITION":
            play(waitingTransition, 0.3, value);
            break;
        case "STOPWAITINGTRANSITION":
            stop(waitingTransition);
            break;
        case "NOTVOTE":
            play(notVote, 0.3, value, false);
            break;
        case "STOPNOTVOTE":
            stop(notVote);
            break;
        case "VOTINGTRANSITION":
            play(votingTransition, 0.3, value);
            break;
        case "CORRECT":
            play(correct, 0.3, value);
            break;
        case "WRONG":
            play(wrong, 0.1, value);
            break;
        case "CLAP":
            play(clap, 0.2, value);
            break;
        case "MUTE":
            clickSound.volume = 0;
            fastFourSec.volume = 0;
            waitingTransition.volume = 0;
            notVote.volume = 0;
            formToVote.volume = 0;
            bigCountdown.volume = 0;
            votingTransition.volume = 0;
            bgMusic.volume = 0;
            correct.volume = 0;
            wrong.volume = 0;
            clap.volume = 0;
            break;
        case "UNMUTE":
            clickSound.volume = 0.3;
            fastFourSec.volume = 0.3;
            waitingTransition.volume = 0.3;
            notVote.volume = 0.3;
            formToVote.volume = 0.2;
            bigCountdown.volume = 0.3;
            votingTransition.volume = 0.3;
            bgMusic.volume = 0.2;
            correct.volume = 0.3;
            wrong.volume = 0.1;
            clap.volume = 0.2;
            break;
        default:
            break;
    }
};
const play = (sound, volume, value, refresh = true) => {
    try {
        if (refresh) {
            sound.pause();
            sound.currentTime = 0;
        }
        sound.volume = value ? volume : 0;
        sound.play();
    }
    catch (error) {
        console.log(error);
    }
};
const stop = (sound) => {
    try {
        sound.pause();
        sound.currentTime = 0;
    }
    catch (error) {
        console.log(error);
    }
};
