<script>
	import { onDestroy } from "svelte";
	import {
		dbData,
		dbHost,
		dbPage,
		dbShuffledUserList,
		dbUserData,
		dbThemeItem,
		listenOnFirebaseKey,
		user,
		dbWritingTimer,
		dbSelectionTimer,
		dbGameTimer,
		dbGameSessionRoundValue
	} from "../services/database";
	import { myUserStore, otherUsersStore, sound } from "../services/store";
	import { shuffle } from "../services/utils";
	import GameHeader from "../components/GameHeader.svelte";
	import { PrimaryButton } from "da-components/v0";
	import TickFalse from "../icons/tickFalse.svelte";
	import TickTrue from "../icons/tickTrue.svelte";
	import { ProfilePicture } from "da-components/v0";
	import Pinocchio from "../icons/pinocchio.svelte";
	import { Sounds, playSound } from "../services/audio";
	import { info } from "../services/notifier";

	export let isHost;

	let truth1 = "";
	let truth2 = "";
	let lie = "";
	let truth1Warning = false;
	let truth2Warning = false;
	let lieWarning = false;
	let userData;
	let otherUsers = [];
	let myUser;
	let disabled;
	let allData = {};
	let host;
	let themeContent = "about yourself";
	let themeItemValue;
	let writingTimer = 63;
	let setWaitingPage = false;
	let waitingScreenData = [];
	let onlineUsersUnanswered = 0;
	let waitingText = "Time's Up! You got skipped but you can still vote"

	const otherUsersStoreUnsubscribe = otherUsersStore.subscribe(value => otherUsers = value);
	const myUserStoreUnsubscribe = myUserStore.subscribe(value => myUser = value);

	onDestroy(() => {
		otherUsersStoreUnsubscribe();
		myUserStoreUnsubscribe();
	});

	listenOnFirebaseKey(dbHost, val => host = val);
	listenOnFirebaseKey(dbThemeItem, val => {
		themeItemValue = val?.value;

		if (themeItemValue !== undefined) {
			const themeSep = themeItemValue.toLowerCase().split("write two truths and one lie ");
			if (themeSep.length === 1) themeContent = themeSep[0];
			else themeContent = themeSep[1];
		} else {
			themeContent = "about yourself";
		}
	});

	listenOnFirebaseKey(dbData(), val => allData = val);

	listenOnFirebaseKey(dbUserData(), val => {
		userData = val;
		truth1 = userData.truth1;
		truth2 = userData.truth2;
		lie = userData.lie;
	});

	listenOnFirebaseKey(dbWritingTimer(), val => {
		writingTimer = val;

		// warning tooltip logic
		if (writingTimer <= 13) {
			if (truth1 === "") truth1Warning = true;
			if (truth2 === "") truth2Warning = true;
			if (lie === "") lieWarning = true;
		}

		if (writingTimer <= 7 && allData?.[myUser.id]?.info?.lie === undefined) {
			playSound(Sounds.fastfourSec);
		}

		if (writingTimer <= 3) {
			setWaitingPage = true;
			playSound(Sounds.stopfastfourSec);
			if (allData?.[myUser.id]?.info?.lie === undefined ) playSound(Sounds.notVote);
		}

		if (writingTimer <= 3 && truth1.trim() !== "" && truth1.trim() !== "" && lie.trim() !== "" && userData === undefined) {
			dbUserData()
			.set({
				truth1,
				truth2,
				lie,
			})
			.then(() => {
				return dbData().once("value");
			})
			.then(snap => {
				if (snap.exists()) {
					allData = snap.val();
				}

				info("Response was submitted automatically");
			});
		}

		// Move to the next page
		if (writingTimer <= 0) {
			shuffleUsersAndSetPage();
		}
	});

	$: isHost = host === user.id;
	$: disabled = !truth1.trim() || !truth2.trim() || !lie.trim() || userData;
	$: onlineUsers = otherUsers.filter(user => user.online);

	// checks if all the online users have submitted their responses
	$: {
		if (isHost && setWaitingPage && onlineUsers.length) {
			let canShowLieChoosePage = true;
			for (let i = 0; i < onlineUsers.length; i++) {
				const user = onlineUsers[i];
				if (!allData?.[user.id]?.info?.lie) {
					canShowLieChoosePage = false;
					break;
				}
			}
			if (canShowLieChoosePage) {
				shuffleUsersAndSetPage();
			}
		}
	}

	/**
	 * this is used to create an array of online users,
	 * which is to be displayed in the waiting screen
	*/
	$: {
		onlineUsersUnanswered = 0;
		for (let i = 0; i < onlineUsers.length; i++) {
			const user = onlineUsers[i];
			let isAnswered = false;

			if (allData?.[user.id]?.info?.lie) isAnswered = true;
			else onlineUsersUnanswered += 1;

			waitingScreenData[i] = {...user, isAnswered };
		}
	}

	/**
	 * checks if the current user has submitted the response
	 * if the current user has submitted the response, the waiting 
	 * room will be displayed.
	*/
	$: {
		if (myUser !== undefined && allData?.[myUser.id]?.info?.lie !== undefined) {
			setWaitingPage = true;
			waitingText = "Time's Up! Get ready to vote & catch the lies.";
		}
	}

	async function shuffleUsersAndSetPage() {
		const allLiars = Object.keys(allData || {}).filter(userId => allData?.[userId]?.info?.lie);
		const shuffleUserList = shuffle(allLiars);
		dbShuffledUserList().set(shuffleUserList);
		if (shuffleUserList[0] === undefined) {
			await dbGameTimer().set(true);
			await dbGameSessionRoundValue.transaction(count => {
				return count + 1;
			});
		} else {
			playSound(Sounds.waiting);
			playSound(Sounds.stopfastfourSec);
			playSound(Sounds.stopNotVote);
			setTimeout(() => {
				playSound(Sounds.stopwaiting);
			}, 1000);
			await dbPage().set(`LIE_CHOOSE*${shuffleUserList[0]}`);
			await dbWritingTimer().remove();
			await dbSelectionTimer().set(60);
		}
	}

	const  buttonClickHandler = () => {
		if (disabled) return;
		dbUserData()
			.set({
				truth1,
				truth2,
				lie,
			})
			.then(() => {
				return dbData().once("value");
			})
			.then(snap => {
				if (snap.exists()) {
					allData = snap.val();
				}
			});
	}

	const handleTruthOne = (e) => {
		truth1 = e.target.value.trim();
		if (truth1 === "" && writingTimer < 10) truth1Warning = true;
		else truth1Warning = false;
	}

	const handleTruthTwo = (e) => {
		truth2 = e.target.value.trim();
		if (truth2 === "" && writingTimer < 10) truth2Warning = true;
		else truth2Warning = false;
	}

	const handleFalse = (e) => {
		lie = e.target.value.trim();
		if (lie === "" && writingTimer < 10) lieWarning = true;
		else lieWarning = false;
	}


</script>


<div>
	<div class="GameHeaderWrapper">
		<GameHeader score={Math.max(0, 0)} time={writingTimer - 3} {isHost} />
	</div>

	<div class="InputContainer">
		<div class="ContentContainer">
			<div class="contentStatement">Write Two truth and one lie</div> 
			<div class="contentTheme">...{themeContent.toUpperCase()} </div>
		</div>

		<div class="TruthLieContainer" class:waitingPage={setWaitingPage}>
			{#if !setWaitingPage}
				<div class="statementContainer">
					<TickTrue />
					<textarea 
						class="inputText greenText"
						type="text"
						on:input={handleTruthOne} 
						rows="4"
						maxlength={100}
						placeholder="ADD A TRUTH HERE"
					/>
					<div class="tooltip" class:hideWarning={!truth1Warning}>Fill your fields fast or you will get skipped</div>
					{#if truth1.length > 90}
						<div class="limitWarning">
							{truth1.length} / 100
						</div>
					{/if}
				</div>
				

				<div class="statementContainer">
					<TickTrue />
					<textarea 
						class="inputText greenText" 
						type="text" 
						on:input={handleTruthTwo} 
						rows="4"
						maxlength={100}	
						placeholder="ADD ANOTHER TRUTH HERE"
					/>
					<div class="tooltip" class:hideWarning={!truth2Warning}>Fill your fields fast or you will get skipped</div>
					{#if truth2.length > 90}
						<div class="limitWarning">
							{truth2.length} / 100
						</div>
					{/if}
				</div>

				<div class="statementContainer falseStatement">
					<TickFalse />
					<textarea 
						class="inputText redText" 
						type="text" 
						on:input={handleFalse} 
						rows="4"
						maxlength={100}
						placeholder="ADD YOUR LIE HERE" 
					/> 
					<div class="tooltip" class:hideWarning={!lieWarning}>Fill your fields fast or you will get skipped</div>
					{#if lie.length > 90}
						<div class="limitWarning">
							{lie.length} / 100
						</div>
					{/if}
				</div>

				<PrimaryButton width="200" text="SUBMIT" {disabled} onClick={buttonClickHandler}>SUBMIT</PrimaryButton>

				<div class="footerStatement">Don’t worry we will shuffle the order of statements :)</div>
			{:else}
				<div class="numberWaiting">
					{#if writingTimer > 3}
						Waiting for {onlineUsersUnanswered} others to submit their responses
					{:else}
						{waitingText}
					{/if}
					
				</div>
				 <div class="waitingGrid">
					 {#each waitingScreenData as item}
						<div class="waitingItem">
							<ProfilePicture 
								size="30" 
								userName={item.userName} 
								src={item.profilePicture}
							/> 
							{item.userName.split("").length < 8 ? item.userName : item.userName.split("").splice(0,8).join("")} 
							{#if item.isAnswered} 
								<div class="pinocchio">
									<Pinocchio />
								</div>
							{/if}
						</div>
					{/each}
				</div> 
			{/if}
		</div>
	</div>
</div>
<style>
	.GameHeaderWrapper {
		z-index: 2;
	}

	.InputContainer {
		display: inline-flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100vw;
		gap: 1rem;
		margin-top: 20vh;
	}

	.ContentContainer, .TruthLieContainer {
		font-family: Poppins;
		display: inline-flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		padding: 24px;
		color: #373637;
		background: #FFFFFF;
		border: 3px solid #373637;
		box-shadow: -8px 8px 0px rgba(73, 63, 63, 0.25);
		border-radius: 12px;
		overflow: auto;
		min-width: 33rem;
		max-width: 33rem;
	}

	.ContentContainer {
		flex-direction: column;
	}

	.TruthLieContainer {
		gap: 1rem;
		min-height: 30rem;
		max-height: 30rem;
	}

	.contentStatement {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		display: block;
		text-transform: uppercase;
		color: #373637;
		opacity: 0.5;
	}

	.inputText {
		border: none;
    	outline: none;

    	-webkit-box-shadow: none;
    	-moz-box-shadow: none;
   		box-shadow: none;
		overflow: hidden;

    	resize: none;
		background: transparent;
		padding: 1rem 1rem;
		height: 7vh;
		font-weight: 800;
		text-align: center;

		font-family: 'Poppins';
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		width: 83%;
	}

	.greenText {
		color: var(--SUCCESS_COLOR);
	} 

	.redText {
		color: var(--FAIL_COLOR);
	}

	.hideWarning {
		display: none;
	}

	.contentTheme {
		display: block;
		text-align: center;
		font-size: 18px;
		font-weight: 800;
	}
	.statementContainer {
		display: inline-flex;
		flex-direction: row;
		gap: 1rem;
		background: #FBFBFB;
		border: 2px solid #55B185;
		border-radius: 6px;
		padding: 0rem 0.5rem;
		color: #55B185;
		align-items: center;
		justify-content: space-between;
	}

	.falseStatement {
		border: 1.5px solid #DA544E;
		color: #DA544E;
	}

	.footerStatement {
		font-size: 12px;
		opacity: 0.5;
	}

	.tooltip {  
		position: absolute;
		margin-left: 28.5rem;
		padding: 0.7rem 0.5rem;
		font-weight: 600;
		font-size: 0.7rem;
		line-height: 1.5rem;
		color: #DA544E;
		border: solid #DA544E;
		background: #FFFFFF;
		border-radius: 0.5rem;
	}

	.tooltip::before {
		position:absolute;					
        left:-22px;    									
        content:'';							
        height:0;
        width:0;
        border:10px solid transparent;
        z-index:1; 
		border-right-color:#DA544E;
	}

	.tooltip::after {
		position:absolute; 					
        left:-18px; 						
        content:''; 					
        height:0;
        width:0;
        border:10px solid transparent;
        z-index:2; 			
		border-right-color:#FFFFFF;
	}

	.waitingGrid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 0.7rem 1rem;
	}
	.waitingItem {
  		display: inline-flex;
		flex-wrap: wrap;
		font-weight: bold;
		gap: 0.2rem;
		flex-direction: row;
		align-items: center;
		padding: 1.3rem 1rem;
		width: 13rem;
		background: #F7F7F7;
		border-radius: 0.5rem;
	}

	.waitingPage{
		justify-content: space-evenly;
		align-content: flex-start;
	}

	.pinocchio {
		margin-left: auto;
	}

	.limitWarning {
		position: absolute;
		margin-top: 50px;
		margin-left: 24rem;
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;

		/* identical to box height */
		text-align: center;

		/* Game/red */
		color: #DA544E;
	}

	@media screen and (max-height: 800px) and (orientation: landscape) {

		.InputContainer {
			margin-top: 16vh;
		}
		.ContentContainer {
			padding: 12px;
		}

		.TruthLieContainer {
			gap: 1rem;
			min-height: 26rem;
			max-height: 26rem;
		}

		.statementContainer {
			padding: 0rem 0.3rem;
			width: 29rem;
		}

		.inputText {
			padding: 1rem 0.5rem;
			height: 8vh;
		}
	}

	@media screen and (max-height: 650px) and (orientation: landscape) {
		.statementContainer {
			padding: 0.2rem 0.4rem;
			width: 29rem;
			gap: 0.5rem;
		}

		.inputText {
			padding: 1rem 0.5rem;
			height: 8vh;
		}

		.TruthLieContainer {
			gap: 0.6rem;
			min-height: 63vh;
			max-height: 63vh;
		}

		.limitWarning {
			margin-top: 44px;
			margin-left: 25rem;
		}
	}

</style>