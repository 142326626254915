import * as Sentry from "@sentry/browser";
// import { Integrations } from "@sentry/tracing";
import App from "./App.svelte";
if (process.env.APP_ENV === "prod") {
    Sentry.init({
        dsn: "https://f6c5f0ae18b94bf6b257a6c20cacb325@o422923.ingest.sentry.io/5845187",
        environment: process.env.APP_ENV,
        // integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
const app = new App({
    target: document.body,
    props: {},
});
export default app;
