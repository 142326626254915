export const params = new URLSearchParams(window.location.search);
export const getParams = function (name) {
    return "" + params.get(name);
};
export function isEqual(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
}
export function shuffle(arr) {
    const array = [...arr];
    var currentIndex = array.length, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
}
export const getGameSessionId = function () {
    return `${getParams("roomId")}+${getParams("sessionId")}`;
};
export const getFirstLetter = function (str) {
    if (!str) {
        return "";
    }
    return str.split("")[0];
};
export function validUserProfilePicture(str) {
    try {
        new URL(str);
        return true;
    }
    catch (error) {
        return false;
    }
}
export function sortUsers(users) {
    return [...users].sort((a, b) => a.id - b.id);
}
export const BASE_COLOR = "#E78843";
export const SECONDARY_COLOR = "#7743E7";
export const SUCCESS_COLOR = "#55B185";
export const FAIL_COLOR = "#DA544E";
export const DARK_COLOR = "#373637";
export const WHITE_COLOR = "#FFFFFF";
