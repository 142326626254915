<script>
	import { onDestroy } from "svelte";
    import { dbCountDownTimer, dbGameTimer, dbPage, dbUserScoreList } from "../services/database"
	import { playSound } from "../services/audio";
	import { usersStore } from "../services/store";
	import { LobbyScreen } from "da-components/v0";
	import Header from "../components/Header.svelte";
	import { danger } from "../services/notifier";

	export let isHost;
	export let hostId;
	export let hostName;

	let onlineUsers = [];
	let offlineUsers = [];
	let noOfOnlinePlayers;
	let users = [];
	
	const usersStoreUnsubscribe = usersStore.subscribe(value => {
		users = Object.values(value);
	});
    
	$: {
		if (users.length !== 0) {
			onlineUsers = [];
			offlineUsers = [];
			noOfOnlinePlayers = 0;
			for (const id in users) {
				if (users[id]?.online === true) {
					onlineUsers.push(users[id]);
					noOfOnlinePlayers += 1;
				} else if (users[id]?.online !== true) {
					offlineUsers.push(users[id]);
				}
			}
		}
	}

	const startGameHandler = async () => {
		playSound("CLICK");

		if (onlineUsers.length > 1) {
			try {
				/**
				 * first update the page to Timer.
				 * then start the CountDown timer.
				 */
				const initialScore = {};
				
				onlineUsers.forEach(item => {
					initialScore[item.id] = 0;
				})

				await dbGameTimer().set(false);
				await dbUserScoreList().set(initialScore);
				await dbPage().set("COUNTDOWN_SCREEN");
				await dbCountDownTimer().set(3);
			} catch (err) {
				console.log(err);
			}
		} else {
			danger("Number of online users must be 2 or more for the game to begin!");
		}
	};

	onDestroy(() => {
		usersStoreUnsubscribe();
	});
</script>
<div class="lobbyRoot">
	<div class="headerWrapper">
		<Header {isHost} />
	</div>
	<div class="lobbyWrapper">
		<LobbyScreen
			{onlineUsers}
			{offlineUsers}
			{isHost}
            handleStartGame={startGameHandler}
			bottomText="GREAT FOR GETTING TO KNOW EACH OTHER & TO SPILL STORIES WITHIN SMALL GROUPS. GET CREATIVE & DECEIVE THE FOLKS."
			{hostId}
			{hostName}
		/>
	</div>
</div>
<style>
	.lobbyRoot {
		width: 100%;
		height: 100%;
		display: flex;
		background-position: center;
	}
	.lobbyWrapper {
		width: 100%;
    	font-size: 24px;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	transform: translate(-50%, -50%);
	}
	.headerWrapper {
		z-index: 2;
	}
</style>