<script>
	import { Header } from "da-components/v0";
	import { sound } from "../services/store";
	import { playSound, Sounds } from "../services/audio";
	import { dbTheme, listenOnFirebaseKey } from "../services/database";

	export let isHost = false;
	let isSoundOn = false;
	let hostRoundController = false;
	let theme;
	let themeObj = {
		themeName: "Default Theme",
		themeEmoji: "🎁",
	};
	let isUnbundledGame = true;

	try {
		isUnbundledGame = !(process.env.DIVE_URLS.split(",") || []).includes(new URL(document.referrer).hostname);
	} catch (_) {
		try {
			isUnbundledGame = !(process.env.DIVE_URLS.split(",") || []).includes(
				new URL(document.location.ancestorOrigins[0]).hostname
			);
		} catch (_) {
			isUnbundledGame = true;
		}
		isUnbundledGame = true;
	}
	$: console.log("isUnbundled", isUnbundledGame);

	function toggleHostRoundController() {
		playSound(Sounds.click);
		hostRoundController = !hostRoundController;
	}

	listenOnFirebaseKey(dbTheme(), val => {
		theme = val;
	});

	async function endRound() {
		playSound(Sounds.click);
		await dbGameSessionRoundValue.transaction(count => {
			return count + 1;
		});
	}
	$: soundState = $sound;

	$: if (soundState === false) {
		playSound(Sounds.mute);
		isSoundOn = false;
	} else {
		isSoundOn = true;
		playSound(Sounds.unmute);
	}

	$: {
		if (theme !== undefined) {
			themeObj = {
				themeEmoji: theme.icon,
				themeName: theme.name,
			};
		}
	}

	const soundButtonHandler = isSoundOn => {
		if (isSoundOn === true) {
			isSoundOn = true;
			sound.update(() => true);
			playSound(Sounds.click);
		} else if (isSoundOn === false) {
			playSound(Sounds.click);
			isSoundOn = false;
			sound.update(() => false);
		}
	};
</script>

{#if !isUnbundledGame}
	<Header
		theme={themeObj}
		themesHandler={() => (isHost ? window?.DIVE_APP?.changeToAppThemePage?.() : "")}
		gameNameSvg="/svg/logo.svg"
		{isHost}
		{soundButtonHandler}
		{isSoundOn}
		themeSupported={true}
	/>
{/if}

<style>
</style>
