<script>
	import { onDestroy, onMount, afterUpdate } from "svelte";
	import Notifications from "../components/Notifications.svelte";
	import {
		dbGameSessionRounds,
		dbGameSessionRoundValue,
		dbGameTimer,
		dbHost,
		dbPage,
		dbUsers,
		listenOnFirebaseKey,
		user,
	} from "../services/database";
	import { danger, info } from "../services/notifier";
	import { myUserStore, otherUsersStore, usersStore, exitModalStore } from "../services/store";
	import { getParams, isEqual } from "../services/utils";
	import SplashScreen from "./SplashScreen.svelte";
	import LobbyScreen from "./LobbyScreen.svelte";
	import LieChooserScreen from "./LieChooserScreen.svelte";
	import WriteScreen from "./WriteScreen.svelte";
	import CountDownScreen from "./CountDownScreen.svelte";	
	import FiveSecTimer from "./FiveSecTimer.svelte";
	import LeaderBoardScreen from "./LeaderBoardScreen.svelte";
	import { playSound, Sounds } from "../services/audio";
	import { ExitModalScreen } from "da-components/v0";
	
	let page;
	let users = [];
	let hostId;
	let hostName;
	let readyListen = false;
	let splashScreenInterval;
	let otherUsers;
	let isHost = false;

	const otherUsersStoreUnsubscribe = otherUsersStore.subscribe(value => {
		otherUsers = value;
	});

	const usersStoreUnsubscribe = usersStore.subscribe(value => {
		users = Object.values(value);
	});
	
	$: {
		if (isHost && users.length !== 0) {
			dbHost.get().then(snap => {
				if (!snap.val()) {
					dbHost.set(user.id);
				}
			});
		}
	};

	$: {
		if (user.id === hostId) {
			dbHost.onDisconnect().remove();
		}
	}

	$: isHost = user.id === hostId;

	$: onlineUsers = otherUsers?.filter(user => user.online);

	$: if (users.length !== 0 && !readyListen) {
		readyListen = true;
		
		listenOnFirebaseKey(dbHost, val => {
			if (hostId) {
				const oldHostName = users.find(user => user.id === hostId)?.userName;
				const newHostName = users.find(user => user.id === val)?.userName;

				hostName = newHostName;
				
				let message = "";
				if (hostId === user.id) {
					message = `${oldHostName || "Old Host"} has left the game and you are the new host`;
				} else {
					if (newHostName) {
						message = `${oldHostName || "Old Host"} has left the game and new host is ${newHostName}`;
					} else {
						message = `${oldHostName || "Old Host"} has left the game and new host has been assigned`;
					}
				}
				info(message);
			}
			hostId = val;
			hostName = users.find(user => user.id === val)?.userName;
		});
	}

	function onPageChange(snap) {
		if (!snap.exists()) {
			page = "SPLASH_SCREEN";
			clearTimeout(splashScreenInterval);
			splashScreenInterval = setTimeout(() => {
				page = "LOBBY_SCREEN"
			}, 2000);
			return;
		}

		clearInterval(splashScreenInterval);
		page = snap.val();

	}

	let roundValue;
	var dbPageKey;
	dbGameSessionRoundValue.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		roundValue = snap.val();
		if (dbPageKey) {
			dbPageKey.off("value", onPageChange);
		}
		dbPageKey = dbGameSessionRounds.child(roundValue).child("page");
		dbPageKey.on("value", onPageChange);
	});

	listenOnFirebaseKey(dbUsers, val => {
		usersStore.set(val);
		myUserStore.set(users.find(u => u.id === user.id));
	});

	let timer;
	let oldOtherUserList = [];

	onMount(() => {
		isHost = getParams("isHost") === "true";

		timer = setInterval(() => {
			const allOtherUsers = users.filter(u => u.id !== user.id);
			const otherUserList = [];
			allOtherUsers.forEach(user => {
				if (user.online === true) {
					otherUserList.push({ ...user, online: true });
				} else if (typeof user.online === "number") {
					if (Date.now() - user.online > 5000) {
						otherUserList.push({ ...user, online: false });
					} else {
						otherUserList.push({ ...user, online: true });
					}
				} else {
					otherUserList.push({ ...user, online: false });
				}
			});
			if (!isEqual(otherUserList, oldOtherUserList)) {
				otherUsersStore.set(otherUserList);
				oldOtherUserList = otherUserList;
			}
		}, 1000);
	});
	onDestroy(() => {
		clearInterval(timer);
		clearInterval(splashScreenInterval);
		otherUsersStoreUnsubscribe();
		usersStoreUnsubscribe();
	});

	afterUpdate(() => {
		if (page === "SPLASH_SCREEN" || page === "LOBBY_SCREEN") {
			playSound(Sounds.bg);
		} else {
			playSound(Sounds.stopbg);
		}
	});

	$: {
		if ( (page === "COUNTDOWN_SCREEN" || page === "WRITE_SCREEN" ||  page?.slice(0, 10) === "LIE_CHOOSE") && (
			onlineUsers !== undefined && onlineUsers.length < 1) ) {
				setTimeout(() => {
					if (onlineUsers !== undefined && onlineUsers.length < 1) {
						endRound();
						danger("Number of online users have dropped to less than 2, returning to lobby");
					}
				}, 5000);
		}
	}

	async function endRound() {
		await dbGameTimer().set(true);
		await dbGameSessionRoundValue.transaction(count => {
			return count + 1;
		});
		window?.DIVE_APP?.changeToAppThemePage?.();
	}
</script>

{#if page === "SPLASH_SCREEN"}
	<SplashScreen />
{:else if page === "LOBBY_SCREEN"}
	<LobbyScreen {isHost} {hostId} {hostName} />
{:else if page === "COUNTDOWN_SCREEN"}
	<CountDownScreen {isHost} />
{:else if page === "WRITE_SCREEN"}
	<WriteScreen />
{:else if page === "LEADERBOARD_SCREEN"}
	<LeaderBoardScreen {isHost} />
{:else if page === "COMPILING_SCORE_SCREEN"}
	<FiveSecTimer {isHost} />
{:else if page?.slice(0, 10) === "LIE_CHOOSE"}
	<LieChooserScreen {page} />
{:else}
	<div/>
{/if}

{#if $exitModalStore === true}
	<div class="exitModalWrapper">
		<ExitModalScreen
			gameOrRound="round"
			onMouseDown={() => playSound(Sounds.click)}
			startNew={() => {
				endRound();
				info("Host has ended the round");
				exitModalStore.set(false);
			}}
			goBack={() => {
				exitModalStore.set(false);
			}}
		/>
	</div>
{/if}

<Notifications />

<style>
	.exitModalWrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 3;
	}
	:root {
		--base: hsl(25.2,77.4%,58.4%);
		--base-l-25: hsl(25, 77%, 69%);
		--base-l-60: hsl(25, 77%, 83%);
		--base-d-60: hsl(25, 77%, 23%);
		--BASE_COLOR: "#E78843";
		--SECONDARY_COLOR: "#7743E7";
		--SUCCESS_COLOR: "#55B185";
		--FAIL_COLOR: "#DA544E";
		--DARK_COLOR: "#373637";
		--WHITE_COLOR: "#FFFFFF";
		font-family: "Pally";
	}
</style>
