import { writable } from "svelte/store";
import { BASE_COLOR } from "./utils";
export const otherUsersStore = writable([]);
export const myUserStore = writable();
export const usersStore = writable([]);
export const notification = writable();
export const sound = writable(true);
export const coinAnimationStore = writable([]);
export const coinCounterBindStore = writable();
export const exitModalStore = writable(false);
export const rulesModalStore = writable(true);
export const bgColor = writable(BASE_COLOR);
