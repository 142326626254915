<script>
	import { onDestroy } from "svelte";
	import { dbData, dbHost, dbSelectionTimer, dbLeaderBoardTimer, dbThemeItem, listenOnFirebaseKey, user, dbPage, dbUserScoreList } from "../services/database";
	import { otherUsersStore, usersStore } from "../services/store";
	import GameHeader from "../components/GameHeader.svelte";
	import { ProfilePicture } from "da-components/v0";
	import QuestionHolder from "../components/QuestionHolder.svelte";
	import { playSound, Sounds } from "../services/audio";
	import { shuffle } from "../services/utils";
	import { info } from "../services/notifier";

	let page;
	let users = {};
	let hostId;
	let votedAnswer;
	let backgroundColor;
	let otherUser;
	let questionData = {};
	let shuffledQuestionData = [];
	let timer = 60;
	let leaderboardState = false;
	let truth1Users = [];
	let truth2Users = [];
	let lieUsers = [];
	let hasAnswered = false;
	let isUserTurn = false;
	let otherUsers = [];
	let themeContent = "...about yourself";
	let themeItemValue;
	let dbQuestionsDataKey;
	let userId;
	let numberAnsweredUsers = 0;
	let visible = false;
	let userScore = 0;
	let selectedAnswer;

	const usersStoreUnsubscribe = usersStore.subscribe(value => {
		users = value;
	});

	const otherUsersStoreUnsubscribe = otherUsersStore.subscribe(value => {
		otherUsers = value;
	});

	onDestroy(() => {
		usersStoreUnsubscribe();
		otherUsersStoreUnsubscribe();
	});

	listenOnFirebaseKey(dbHost, val => (hostId = val));
	
	listenOnFirebaseKey(dbSelectionTimer(), val => {
		// if value is reset to 60 => we are on the next user's page
		timer = val - 1;

		if (val <= 5 && votedAnswer === undefined && !isUserTurn) playSound(Sounds.fastfourSec);

		if (val <= 1) {
			if (!hasAnswered && selectedAnswer !== undefined) {
				const dbQuestionsData = dbData().child(userId);
				const dbAnswersData = dbQuestionsData.child("answers");
				const dbAnswerData = dbAnswersData.child(user.id);
				dbAnswerData
					.set(selectedAnswer)
					.then(() => {
						hasAnswered = true;
						info("Selected option was automatically submitted");
					})
					.catch(err => console.log(err));
			}
			playSound(Sounds.stopfastfourSec);
			playSound(Sounds.votingTransition);
		} 

		if (isHost && numberAnsweredUsers === onlineUsers.length && !leaderboardState) {
			playSound(Sounds.stopfastfourSec);
			(async () => {
				await dbSelectionTimer().remove();
				await dbLeaderBoardTimer().set(10);
			})();
		}
	});

	listenOnFirebaseKey(dbUserScoreList().child(user.id), val => {
		userScore = val;
	});

	listenOnFirebaseKey(dbPage(), val => page = val );

	listenOnFirebaseKey(dbThemeItem, val => {
		themeItemValue = val?.value;

		if (themeItemValue !== undefined) {
			const themeSep = themeItemValue.toLowerCase().split("write two truths and one lie ");
			if (themeSep.length === 1) themeContent = themeSep[0];
			else themeContent = themeSep[1];
		} else {
			themeContent = "...about yourself";
		}
	});

	listenOnFirebaseKey(dbLeaderBoardTimer(), val => {
		timer = val;

		if (leaderboardState === false) leaderboardState = true;
	});

	function onButtonClick(answer) {
		playSound(Sounds.click);
		const dbQuestionsData = dbData().child(userId);
		const dbAnswersData = dbQuestionsData.child("answers");
		const dbAnswerData = dbAnswersData.child(user.id);
		dbAnswerData
			.set(answer)
			.then(() => {
				hasAnswered = true;
			})
			.catch(err => console.log(err));
	}

	function clickHandler(option) {
		if (isUserTurn) return;

		if (!hasAnswered && !leaderboardState) {
			playSound(Sounds.click);
			selectedAnswer = option;
		}
	}


	$: {
		if (page) {
			if (page?.includes("LIE_CHOOSE*")) {
				userId = page.split("LIE_CHOOSE*")[1].split("*")[0];
			}
		}
	} 

	$: isHost = hostId === user.id;
	
	// user who wrote the question
	$: otherUser = users[userId];
	$: hasAnswered = !!questionData?.answers?.[user.id];
	$: isUserTurn = user.id === userId;
	$: totalUsers = [user, ...otherUsers];
	$: onlineUsers = totalUsers.filter(user => user.online);

	$: {
		if (page) {
			visible = true;
			leaderboardState = false;
			backgroundColor = undefined;
			votedAnswer = undefined;
			hasAnswered = false;
			selectedAnswer = undefined;
			shuffledQuestionData = [];
			playSound(Sounds.waiting);
		}
	}

	function questionDataListener(snap) {
		if (snap.exists()) {
			questionData = snap.val();
			if (shuffledQuestionData.length === 0) shuffledQuestionData = shuffle(Object.values(questionData.info));
		}
	}
	
	$: {
		if (userId) {
			if (dbQuestionsDataKey) {
				dbQuestionsDataKey.off("value", questionDataListener);
			}

			dbQuestionsDataKey = dbData().child(userId);
			dbQuestionsDataKey.on("value", questionDataListener);

			const dbAnswerData = dbQuestionsDataKey.child("answers").child(user.id);
			dbAnswerData.on("value", snap => {
				if (snap.exists()) {
					votedAnswer = snap.val();
				}
			});
		}
	}

	$: hostName = totalUsers.find(user => user.id === hostId)?.userName;

 	$: {
		const questionEntries = Object.entries(questionData?.answers || []);
		
		lieUsers = [];
		truth1Users = [];
		truth2Users = [];
		numberAnsweredUsers = 0;

		if (questionEntries.length > 0 && totalUsers.length > 0) {
			questionEntries.forEach((item) => {
				const userDetails = totalUsers.find(elem  => {
					return elem.id === item[0];
				});

				if (userDetails !== undefined) {
					numberAnsweredUsers++;
					if (item[1] === questionData.info.lie) lieUsers = [...lieUsers, userDetails]; 
					else if (item[1] === questionData.info.truth1) truth1Users = [...truth1Users, userDetails]; 
					else truth2Users = [...truth2Users, userDetails];
				}
			})
		}
	}

	// logic for setting the page color
	$: {
		if (leaderboardState === true) {
			if (isUserTurn) {
				if (lieUsers.length === 0) backgroundColor = "#55B185";
				else if (lieUsers.length !== 0 && truth2Users.length === 0 && truth1Users.length === 0) backgroundColor = "#DA544E";
				else backgroundColor = "#7743E7";
			} else {
				if (questionData?.answers?.[user.id] === undefined) backgroundColor = "#DA544E";
				else if (questionData?.answers?.[user.id] === questionData.info.lie) backgroundColor = "#55B185";
				else backgroundColor = "#DA544E";
			}
		}
	}

</script>

<div class="container" style="--bg-color: {backgroundColor || "#7743E7"}">
	<div class="GameHeaderWrapper">
		<GameHeader score={Math.max(0, userScore)} time={timer} {isHost} />
	</div>

	<div class="InputContainer">
		<div class="ContentContainer">
			{#if leaderboardState === false} 
				<div class="contentStatement">{!isUserTurn ? "Identify the lie" : ""}</div> 
				<div class="contentTheme">{!isUserTurn ? "..." + themeContent: "Beware, The team is trying to catch your lie!"}</div>
			{:else}
				<div class="contentTheme">
				{#if isUserTurn }
					{#if lieUsers.length === 0}
						<div class="nextTry">
							No one could catch your lie
						</div>
					{:else if lieUsers.length !== 0 && truth2Users.length === 0 && truth1Users.length === 0}
						<div class="nextTry">
							Everyone caugh your lie! 🤥
						</div>
					{:else}
						<div class="nextTry">
							some folks caught your lie 🤥
						</div>
					{/if}
					
				{:else}
					{#if questionData?.answers?.[user.id] === undefined}
						<div class="nextTry">
							Catch the lie in the next try 🤥
						</div>
					{:else if questionData?.answers?.[user.id] === questionData.info.lie}
						<div class="rightAns">
							Woohoo! You got it right 🍻
						</div>
					{:else}
						<div class="wrongAns">
							You got Pinocchioed! 🤥
						</div>
					{/if}
				{/if}
				</div>
			{/if}
			<div class="peopleAnswered">
				<span>
					<ProfilePicture 
						size="30" 
						userName={otherUser?.userName || ""} 
						src={otherUser?.profilePicture || ""}
					/>
				</span>
				<span>
					{#if isUserTurn}
						It’s your turn
					{:else}
						{otherUser?.userName.length <=8 ? otherUser?.userName : otherUser?.userName.split("").splice(0, 8).join("") + "..." || ""}
					{/if}
					{"(" + numberAnsweredUsers + "/" + onlineUsers.length + ")"}
				</span>
			</div>
		</div>

		<div class="tipContainer">
			{#if isUserTurn}
				Confuse the folks to save your lie!
			{:else}
				Tip 💡: Ask detailed questions to bust the person’s Lie!
			{/if}
		</div>

		{#if visible}
		<QuestionHolder 
			{isUserTurn} 
			{hasAnswered} 
			{leaderboardState} 
			{shuffledQuestionData} 
			{votedAnswer}
			{questionData}
			{lieUsers}
			{truth1Users}
			{truth2Users}
			{onButtonClick}
			{selectedAnswer}
			{clickHandler}
		/>
		{/if}

	</div>
</div>

<style lang="scss">

	@keyframes fadeIn{
		0% {
			opacity:0;
		}
		100% {
			opacity:1;
		}
	}
	.container{
		width: 100vw;
		height: 100vh;
		animation: fadeIn ease 3s;
  		-webkit-animation: fadeIn ease 3s;
		background-image: url("/svg/bg.svg");
		background-position: center;
		background-size: cover;
		background-color: var(--bg-color);
	}
	.GameHeaderWrapper {
		z-index: 2;
	}

	.InputContainer {
		display: inline-flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100vw;
		gap: 3rem;
		margin-top: 20vh;
	}

	.ContentContainer {
		font-family: Poppins;
		display: inline-flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 24px;
		color: #373637;
		background: #ffffff;
		border: 3px solid #373637;
		box-shadow: -8px 8px 0px rgba(73, 63, 63, 0.25);
		border-radius: 12px;
		min-width: 28vw;
		position: relative;
	}

	.contentStatement {
		color: #373637;
		text-transform: uppercase;
		font-size: 14px;
		opacity: 0.5;
		letter-spacing: 2px;
		font-weight: 600;
	}

	.contentTheme {
		text-align: center;
		font-size: 18px;
		font-weight: 800;
	}

	.peopleAnswered {
		display: inline-flex;
		gap: 0.5rem;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0.3rem 1rem;
		color: #e78843;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 66%);
		background: #ffffff;
		border: 2px solid #373637;
		border-radius: 8px;
		white-space: nowrap;
	}

	.tipContainer {
		font-family: Poppins;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		line-height: 21px;

		/* identical to box height */

		/* Game/White */
		color: #FFFFFF;

		opacity: 0.7;
	}
	.rightAns, .nextTry, .wrongAns {
		font-family: 'Poppins';
		font-style: normal;
		font-weight: 700;
		font-size: 1.5rem;
		color: #373637;
	}

	@media screen and (max-height: 800px) and (orientation: landscape) {
			.ContentContainer {
				padding: 1rem;
			}
	}
	
</style>
