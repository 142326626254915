<script>
    import { onMount } from "svelte";

	import Header from "../components/Header.svelte";
    import Bar from "../icons/bar.svelte";
    import { playSound, Sounds } from "../services/audio";

	export let isHost;

    onMount(() => {
        playSound(Sounds.votingTransition);
    })
</script>

<div class="TimerRoot">
	<div class="headerWrapper">
		<Header {isHost} />
	</div>
    <div class="cont">
        <div class="Wrapper">
            <div class="content">Try out different themes of the app & get to know interesting stories about your teammates!</div>
            <div class="footer">
                <Bar />
                <div>
                    Compiling scores...
                </div>
            </div>
        </div>
    </div>
</div>

<style>
	.TimerRoot {
		width: 100%;
		height: 100%;
		display: flex;
        background-image: url("/svg/bg.svg");
		background-position: center;
		background-size: cover;
		background-color: #7743E7;
	}

    .cont {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

	.Wrapper {
		display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 24px;
        position: absolute;
        width: 30rem;
        height: 60vh;
        background: #FFFFFF;
        border: 3px solid #373637;
        box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
	}

    .content {

        font-family: 'Pally';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 43px;
        text-align: center;
        text-shadow: 1px 1px black;
        color: #F7963C;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        margin: 0px 0px;
    }

    .footer {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        bottom: 1rem;
        position: absolute;
        text-align: center;
        color: rgba(0, 0, 0, 0.35);
    }
    
</style>