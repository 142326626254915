<script>
    import { SplashScreen } from "da-components/v0";
    import { BASE_COLOR } from "../services/utils";
</script>

<SplashScreen 
    svgSrc={"/svg/bg.svg"} 
    bgColor={BASE_COLOR} 
    logoSrc={"/svg/splashLogo.svg"} 
    subHeading="🚫 Weave the Lies In Between the Truths ✅" 
/>