<script>
	import { TimerScreen } from "da-components/v0";
	import { dbCountDownTimer, listenOnFirebaseKey } from "../services/database";
	import Header from "../components/Header.svelte";
	import { onMount } from "svelte";
	import { playSound, Sounds } from "../services/audio";
	
	export let isHost;
    let countDown = 3;
    
	onMount(() => {
		listenOnFirebaseKey(dbCountDownTimer(), val => {
			countDown = val;

			if (countDown <= 3) {
				playSound(Sounds.bigCountdown);
			}

			if (countDown <= 0) {
				playSound(Sounds.bigCountdownStop);
				playSound(Sounds.formtovote);
			}
		});
	});

</script>

<div class="TimerRoot">
	<div class="headerWrapper">
		<Header {isHost} />
	</div>
	<div class="TimerWrapper">
        <TimerScreen
            countDown={Math.min(5, countDown)}
            displayCounter={true}
        />
	</div>
</div>

<style>
	.TimerRoot {
		width: 100%;
		height: 100%;
		display: flex;
		background-position: center;
        background-image: url("/svg/bg.svg");
		background-color: var(--base);
		background-size: cover;
	}
	.TimerWrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20vh;
	}
</style>