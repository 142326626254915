<script>
	import { onMount } from "svelte";
	import Header from "../components/Header.svelte";
	import { playSound, Sounds } from "../services/audio";
	import {
		dbGameSessionRoundValue,
		dbUserScoreList,
		getRoundValue,
		listenOnFirebaseKey,
		user,
	} from "../services/database";
	import { usersStore } from "../services/store";
	import { LeaderboardScreen } from "da-components/v0";
	
    export let isHost;
	
    let userId = user.id;
	let scores = [];
	let usersList = [];
	let oneLineMessage = "";
	
    const handleNewGame = async () => {
		await dbGameSessionRoundValue.set(getRoundValue() + 1);
		window?.DIVE_APP?.changeToAppThemePage?.();
	};
	
    onMount(() => {
		listenOnFirebaseKey(dbUserScoreList(), val => {
			scores = Object.entries(val);
			scores.sort((a, b) => b[1] - a[1]);
			formatDataForLeaderboard();
			playSound(Sounds.clap);
		});
	});
	
	const formatDataForLeaderboard = () => {
        let position = 1;
		let current = 0;
		let myPos = 4;
		let sharedTop = false;

		while (current < scores.length) {
			let usersArray = scores
				.slice(current, scores.length)
				.filter(el => el[1] === scores[current][1]);
			usersArray = usersArray.map(el => {
				if (+el[0] === +userId) {
					myPos = position;
				}
				if (position === 1) {
					sharedTop = usersArray.length > 1;
				}
				return {
					name: $usersStore[el[0]].userName,
					score: el[1],
					src: $usersStore[el[0]].profilePicture,
					id: el[0],
				};
			});
			current += usersArray.length;
			usersList[position - 1] = { position, usersArray: usersArray };
			position++;
		}

		//generate one line message here
		if (myPos === 1 && !sharedTop) {
			oneLineMessage = "Wohhoo! You Won 🔥";
		} else if (myPos > 1 && !sharedTop) {
			oneLineMessage = "That’s a Clear Win 🎉";
		} else if (myPos === 1 && sharedTop) {
			oneLineMessage = "You Won & it’s a Tie! 🤝";
		} else if (sharedTop) {
			oneLineMessage = "Damn! It’s a Tie 🤝 Well Played";
		} else {
			oneLineMessage = "Let’s keep going 💪";
		}
		return usersList;
	};
</script>

<div class="leaderboardRoot">
	<div class="headerWrapper">
		<Header {isHost} />
	</div>
	<div class="leaderboardWrapper">
		<LeaderboardScreen
			{isHost}
			continueHandler={handleNewGame}
			users={usersList}
			onMouseDown={() => playSound(Sounds.click)}
			nextRoundOrPlayAgain="Play Again"
			{userId}
			{oneLineMessage}
		/>
	</div>
</div>

<style>
	.leaderboardRoot {
		width: 100%;
		height: 100%;
		display: flex;
		background-position: center;
        background-image: url("/svg/bg.svg");
		background-color: var(--base);
		background-size: cover;
	}
	.leaderboardWrapper {
		width: 100vw;
		height: 100vh;
		font-size: 24px;
		margin-top: 20vh;
	}
	.headerWrapper {
		z-index: 2;
	}
</style>